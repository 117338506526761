import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Storage from '@mui/icons-material/Storage';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import Build from '@mui/icons-material/Build';

// Parasail imports
import PageHeader from "components/PageHeader"
import MiniCard from 'components/views/common/MiniCard';
import InviteUsageView from 'components/views/InviteUsageView';
import { useUserContext } from "components/providers/UserContext";
import ReactGA from "react-ga4";
import { Logger } from "utils/Logger";

const HomeView = () => {
  const { UserContextService } = useUserContext();

  React.useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/home", title: UserContextService.userState.hasActiveAccount ? "home page" : "home page - w/ invite usage view" });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ServerlessContent = () => {
    return (
      <ul style={{minHeight: "170px", listStyle: "disc", paddingLeft: "10px"}}>
        <li className="mb-2" style={{textAlign: "start"}}>
          Easy access to popular LLMs and multimodal models
        </li>
        <li className="mb-2" style={{textAlign: "start"}}>
          Market leading price performance
        </li>
        <li className="mb-2" style={{textAlign: "start"}}>
          Real-time and batch endpoints for performance and cost-optimized workloads
        </li>
      </ul>
    )
  }
  const DedicatedContent = () => {
    return (
      <ul style={{minHeight: "170px", listStyle: "disc", paddingLeft: "10px"}}>
        <li className="mb-2" style={{textAlign: "start"}}>
          Custom models, optimized orchestration, with a latency and uptime SLAs
        </li>
        <li className="mb-2" style={{textAlign: "start"}}>
          Powered by on-demand GPUs at the most competitive price
        </li>
        <li className="mb-2" style={{textAlign: "start"}}>
          Secure and private: you control access to the data and GPUs
        </li>
      </ul>
    )
  }
  const ManagedContent = () => {
    return (
      <ul style={{minHeight: "170px", listStyle: "disc", paddingLeft: "10px"}}>
        <li className="mb-2" style={{textAlign: "start"}}>
          Ultimate level of security, privacy, and multi-cloud flexibility: run endpoints or even our full platform in your cloud environment
        </li>
        <li className="mb-2" style={{textAlign: "start"}}>
          Use your GPUs, our low-priced on-demand GPUs, or both
        </li>
        <li className="mb-2" style={{textAlign: "start"}}>
          Enterprise-grade integrations for data, security and compliance, business processes, and MLOps
        </li>
      </ul>
    )
  }

  Logger.log('Is userState info: ', UserContextService.userState.user, UserContextService.userState.activeAccount, UserContextService.userState.accounts);

  const welcomeMessage = UserContextService.userState.hasActiveAccount ? `Welcome, ${UserContextService.userState.user.username}!` : `Welcome to Parasail!`;

  return (
    <>
      <PageHeader title={welcomeMessage} />
            
      <div style={{display: "flex", alignItems: "stretch", justifyContent: "center"}}>
          { UserContextService.userState.hasActiveAccount ? (
            <>
              <Box className="w-full" style={{marginBottom: "25px"}} sx={{ bgcolor: 'background.default'}}>
                <Grid container spacing={4}>
                  <Grid size={{ xs: 12 }} sx={{ marginTop: "15px", textAlign: "start", display: "flex", justifyContent: "center"}}>
                      <Typography variant="h4" component="div">
                        Serverless, dedicated, and managed enterprise tiers to fit any requirement.
                      </Typography>
                  </Grid>

                  <Grid size={{ xs: 4 }}>
                    <MiniCard cardTitle='Serverless' cardBtnText="Try Serverless" cardLink="/serverless" CardIcon={<Storage fontSize="large" color="primary" />} CardBody={ServerlessContent} />
                  </Grid>
                  <Grid size={{ xs: 4 }}>
                    <MiniCard cardTitle='Dedicated' cardBtnText="Try Dedicated" cardLink="/dedicated" CardIcon={<CloudDoneIcon fontSize="large" color="primary" />} CardBody={DedicatedContent} />
                  </Grid>
                  <Grid size={{ xs: 4 }}>
                    <MiniCard cardTitle='Managed Enterprise' cardBtnText="Try Managed" cardLink="/managed" CardIcon={<Build fontSize="large" color="primary" />} CardBody={ManagedContent} />
                  </Grid>
                </Grid>
              </Box>
          </>
          ) : (
          <>
            <InviteUsageView />
          </>
          )}
      </div>
    </>
  );
};

export default HomeView;