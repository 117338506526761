import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import {
  DataGrid,
  GridToolbar,
  GridColDef,
  GridRenderCellParams,
  GridRowSelectionModel
} from '@mui/x-data-grid';
import CustomNoRowsOverlay from "components/tables/CustomNoRowsOverlay";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Panel, PanelGroup } from "react-resizable-panels";
import { useNavigation } from "react-router-dom";
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import { NavLink, useLoaderData } from "react-router-dom";
import { ServerlessDeployment, Usage } from "react-app-env";

// Parasail imports
import BasicBreadcrumbs from "components/BasicBreadcrumbs";
import PageHeader from "components/PageHeader";
import {  FetchServerlessAPI } from "service/FetchService";
import ModelUsagesPane from "components/views/common/ModelUsagesPane";
import ResizeHandle from "components/views/common/ResizeHandle";
import ModelStatusIcon from 'components/views/common/ModelStatusIcon';
import ReactGA from "react-ga4";
import { Logger } from "utils/Logger";

const columns: GridColDef[] = [
  {
    field: "status",
    width: 40,
    renderHeader: () => "",
    valueGetter: (value, row) => row.status.status,
    renderCell: (params: GridRenderCellParams) => (
      <center>
        <ModelStatusIcon status={params.value} />
      </center>
    ),
  },
  {
    field: "deploymentName",
    width: 250,
    renderHeader: () => (<strong>Serverless Endpoints</strong>),
    valueGetter: (value, row) => (row.deploymentName + (row.version ? (" / " + row.version) : '')),
    renderCell: (params: GridRenderCellParams) => (params.value),
  },
  {
    field: "contextLength",
    width: 150,
    renderHeader: () => (<strong>Context Length</strong>),
  },
  {
    field: "cost",
    width: 150,
    renderHeader: () => (<strong>Cost (1M tokens)</strong>),
    renderCell: (params: GridRenderCellParams) => {
      const cost = params.value ? params.value < .01 ? `< $0.01` : `$${Math.round((params.value + Number.EPSILON) * 100) / 100}` : '';
       return (
       <>      
         {cost}
       </>
       );
     },
  },
];

const ServerlessHomeView = () => {
  // const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
  //   items: [],
  //   quickFilterValues: [],
  // });
  // const [ignoreDiacritics, setIgnoreDiacritics] = React.useState(true);
  const [rowSelectionModelID, setRowSelectionModelID] = React.useState<GridRowSelectionModel>([]);
  const [selectedRowData, setSelectedRowData] = React.useState(Array<ServerlessDeployment>)
  const [usagesData, setUsagesData] = React.useState(Array<Usage>)
  
  const serverlessDeployments = useLoaderData() as ServerlessDeployment[];
  Logger.log('serverlessDeployments', serverlessDeployments);
  React.useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/serverless", title: "severless dashboard page" });
  }, []);

  React.useEffect(() => {
    // call to fetch usage and chat
    const selectedRowId = rowSelectionModelID[0]?.toString();
    Logger.log(selectedRowId);

    if(selectedRowId === '') return;

    const selectedModel = serverlessDeployments.filter(serverlessDeployments => selectedRowId === serverlessDeployments.id?.toString());
    setSelectedRowData(selectedModel);

    if (selectedRowId) {
      FetchServerlessAPI.getUsages(selectedRowId).then((response) => {
        Logger.log('getUsages response', response);
        setUsagesData(response as any);
      }).catch((e) => {
        Logger.log(e);
      });
    }
  }, [rowSelectionModelID, serverlessDeployments]);

  const navigation = useNavigation();

  if (navigation.state === "loading") {
    return <h1>Loading Serverless Dashboard ...</h1>;
  }

  return (
    <>
      <BasicBreadcrumbs root="Home" primary="Serverless Models" url="/home" />

      {/* Page title & Create new model button */}
      <Stack 
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
      >
        <PageHeader title="Serverless Models" />
        <div>
          <span style={{fontWeight: "500", marginRight: "10px"}} className="m-4 py-1 text-med">Ready to create a dedicated model?</span>
          
          {selectedRowData && selectedRowData[0] && selectedRowData[0]?.deploymentName ? (
            <NavLink 
              to={`/dedicated/new?isServerlessDashboard=true&isCloneMode=true&modelName=${selectedRowData[0]?.modelName}&designName=${selectedRowData[0]?.deploymentName}`}
              style={{textTransform :"none"}}
            >
              <Button variant="contained" size="small" startIcon={<CloudDoneIcon />} >
                Clone Dedicated Model
              </Button>
            </NavLink>
          ) : (
            <NavLink to={`/dedicated/new?isServerlessDashboard=true`} style={{textTransform :"none"}}>
              <Button variant="contained" size="small" startIcon={<CloudDoneIcon />} >
                Create Dedicated Model
              </Button>
            </NavLink>
          ) }
        </div>
      </Stack>


      <div className="bg-white dark:bg-slate-800" style={{minHeight: "500px", display: "flex", alignItems: "stretch", justifyContent: "center"}}>
        <Box className="w-full" style={{marginBottom: "25px"}}>
          <PanelGroup direction="horizontal" id="group">
            {/* model list */}
            <Panel id="sidebar" minSize={45}  defaultSize={40} collapsible={true} order={1} style={{minWidth: "700px"}}>
              <div style={{ height: "100%", width: '100%' }}>
                <DataGrid 
                  rows={serverlessDeployments} 
                  columns={columns} 
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 25 },
                    },
                  }}
                  pageSizeOptions={[25, 50, 100]}
                  // filterModel={filterModel}
                  // onFilterModelChange={setFilterModel}
                  slots={{ noRowsOverlay: CustomNoRowsOverlay, toolbar: GridToolbar }}
                  slotProps={{ toolbar: { showQuickFilter: true } }}
                  sx={{ '--DataGrid-overlayHeight': '300px' }}
                  // checkboxSelection
                  disableColumnSelector
                  disableDensitySelector
                  hideFooter
                  // ignoreDiacritics={ignoreDiacritics}
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                    // set the sectioned row model's id
                    setRowSelectionModelID(newRowSelectionModel);
                    Logger.log('selected row', selectedRowData, rowSelectionModelID);
                  }}
                  rowSelectionModel={rowSelectionModelID}
                  // keepNonExistentRowsSelected
                />
              </div>
            </Panel>
        
            <ResizeHandle />
        
            {/* Usage View */}
            <Panel collapsible={true} order={2} style={{border: "1px solid #DDD"}}>
              {selectedRowData && selectedRowData[0] ? (
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                    <Grid container spacing={0} sx={{ width: '100%', minHeight: '500px'}}>
                      <Grid size={{ xs: 12, md: 12 }}>
                        <ModelUsagesPane data={selectedRowData} usagesData={usagesData} />
                      </Grid>
                    </Grid>
                </Stack>
              ) : ''}
            </Panel>
          </PanelGroup>
        </Box>
      </div>
    </>
  );
};

export default ServerlessHomeView;