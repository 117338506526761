import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
// import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
// import Link from '@mui/material/Link';
// import MenuIcon from '@mui/icons-material/Menu';
// import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
// import MailIcon from '@mui/icons-material/Mail';
import Drafts from '@mui/icons-material/Drafts';
// import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import { useNavigate, NavLink } from "react-router-dom";
import { Collapse, List, ListItemButton, ListItemText, ToggleButtonGroup } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ReactGA from "react-ga4";

// Parasail imports
import { useUserContext } from "components/providers/UserContext";
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { FetchCommonAPI } from 'service/FetchService';
import 'styles/common.css';
import { Logger } from "utils/Logger";

export default function PrimarySearchAppBar(props: { toggleDrawer: (newOpen: boolean) => void }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [alignment, setAlignment] = React.useState('web');
  const [isAccountMenuOpen, setIsAccountMenuOpen] = React.useState(false);
  const { UserContextService } = useUserContext();

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setAlignment(newAlignment);
  };

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const navigate = useNavigate();

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMyProfileRoute = () => {
    // Send a custom event
    ReactGA.event({
      category: "navbar",
      action: "navbar-profile-button-click",
      label: "navbar-profile-button-click", // optional
      // value: 1, // optional, must be a number
      nonInteraction: false, // optional, true/false
      // trans port: "xhr", // optional, beacon/xhr/image
    });

    navigate("/userprofile");
  }

  const handleCreateInviteRoute = () => {
    // Send a custom event
    ReactGA.event({
      category: "navbar",
      action: "navbar-invite-button-click",
      label: "navbar-invite-button-click", // optional
      // value: 1, // optional, must be a number
      nonInteraction: false, // optional, true/false
      // trans port: "xhr", // optional, beacon/xhr/image
    });

    navigate("/invite");
  }

  const handleBillingRoute = () => {
    navigate("/billing");
  }

  const menuId = 'primary-search-account-menu';

  const SharedMenuItems = (<div>
          {UserContextService.userState.user.username && (
          <MenuItem>Hi, {UserContextService.userState.user.username}</MenuItem>
        )}
        {UserContextService.userState.activeAccount.accountName && (
          <div>
            <MenuItem
              onClick={() => setIsAccountMenuOpen(!isAccountMenuOpen)}
              style={{ width: "100%" }}
            >
              {UserContextService.userState.activeAccount.accountName}
              {isAccountMenuOpen ? <ExpandLess /> : <ExpandMore />}
            </MenuItem>
            <Collapse in={isAccountMenuOpen} timeout="auto" unmountOnExit sx={{ mb: 1 }}>
              <List component="div" disablePadding>
                {UserContextService.userState.accounts.map((a, index) => {
                  const indexFormatted = index + 1;
                  return (
                  <ListItemButton
                    key={indexFormatted}
                    sx={{ padding: 0, margin: 0, pl: 4 }}
                    onClick={() => {
                      FetchCommonAPI.setAccount(a.accountName || '')
                        .then(() => UserContextService.setActiveAccount(a))
                        .then(() => setIsAccountMenuOpen(false))
                        .then(() => navigate("/home"));}}>
                    <ListItemText primary={a.accountName} />
                  </ListItemButton>
                )})}
              </List>
            </Collapse>
          </div>
        )}
        <Divider />
        <MenuItem onClick={handleMyProfileRoute}>My Profile</MenuItem>
        <MenuItem onClick={handleCreateInviteRoute}>Create Invite</MenuItem>
        <MenuItem onClick={handleBillingRoute}>My Billing</MenuItem>
        <MenuItem>                 
          <button type="submit"
            onClick={ () => {
              UserContextService.Logout();
            }}>
            Log Out
          </button>
        </MenuItem>
  </div>);
  const renderMenu = (
    <>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={menuId}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {SharedMenuItems}
      </Menu>
    </>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem className='bg-white'>
        <Button onClick={handleCreateInviteRoute} color="info" variant="outlined" endIcon={<Drafts />} size="small" style={{backgroundColor: "#fff", textTransform :"none"}}>
          Create Invite
        </Button>
      </MenuItem>
      {/* <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem> */}
        {SharedMenuItems}
    </Menu>
  );

  Logger.log('Navbar called - isAuthenticated?', UserContextService.userState.isAuthenticated);
  return (
    <div
      id="navbar"
      className="col-span-12 h-[3.75rem] flex items-center bg-white"
    >
        <Box sx={{ flexGrow: 1 }}>
          {/* {!showLogoutContent ?  */}
          {/* <ThemeProvider
              theme={createTheme({
              components: {
                  MuiListItemButton: {
                  defaultProps: {
                      disableTouchRipple: true,
                  },
                  },
              },
              palette: {
                  mode: 'dark',
                  primary: { main: 'rgb(102, 157, 246)' },
                  background: { paper: '#3477EC' },
              },
              })}
          > */}
              <AppBar position="static">
                  <Toolbar className="dark:bg-gray-800">
                      <Box>
                        {/* {isAdmin &&
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                sx={{ mr: 0 }}
                                onClick={() => props.toggleDrawer(true)}
                            >
                                <MenuIcon />
                            </IconButton>
                          } */}
                          <IconButton
                              size="large"
                              edge="start"
                              color="inherit"
                              aria-label="parasail icon"
                              sx={{ mr: 2 }}
                          >
                            {/* <Link to='/home'><img src="/parasail.svg" alt="parasail logo" style={{ height: "55px"}}/></Link> */}
                            <NavLink to='/home'>
                              <div className="company-text--light">
                                <h2>PARASAIL</h2>
                              </div>
                            </NavLink>
                          </IconButton>
                          
                          {/* <Typography
                              variant="h6"
                              noWrap
                              component="div"
                              sx={{ display: { xs: 'none', sm: 'block' } }}
                          >
                              Parasail
                          </Typography> 
                          <Search>
                              <SearchIconWrapper>
                              <SearchIcon />
                              </SearchIconWrapper>
                              <StyledInputBase
                              placeholder="Search…"
                              inputProps={{ 'aria-label': 'search' }}
                              />
                          </Search> */}

                          {/* Top SERVERLESS & DEDICATED toggle buttons. Only show if user is logged in */}
                          {UserContextService.userState.isAuthenticated && (
                            <ToggleButtonGroup
                              id="solutions"
                              value={alignment}
                              exclusive
                              onChange={handleChange}
                              aria-label="solutions"
                              fullWidth={false}
                              size="small"
                              color="info"
                              className='text-bold bg-white dark:bg-slate-500'
                            >
                              <ToggleButton onClick={() => navigate("/serverless")} value="serverless" style={{textTransform :"none"}}>
                                Serverless
                              </ToggleButton>
                              <ToggleButton onClick={() => navigate("/dedicated")} value="dedicated" style={{textTransform :"none"}}>
                                Dedicated
                              </ToggleButton>
                              <ToggleButton onClick={() => navigate("/batch")} value="batch" style={{textTransform :"none", minWidth: "82px"}}>
                                Batch
                              </ToggleButton>
                              <ToggleButton onClick={() => navigate("/managed")} value="managed" style={{textTransform :"none"}}>
                                Managed
                              </ToggleButton>
                            </ToggleButtonGroup>
                          )}
                      </Box>
                      <Box sx={{ flexGrow: 1 }} />

                      {UserContextService.userState.isAuthenticated && (
                        <>
                          <Box style={{ alignItems: "center" }} sx={{ display: { xs: 'none', md: 'flex' } }}>
                              <Box className="bg-white dark:bg-slate-500" style={{height: "fit-content"}}>
                                <Button onClick={handleCreateInviteRoute} color="info" variant="outlined" endIcon={<Drafts />} size="small"  style={{borderRadius: "unset", textTransform :"none"}}>
                                  Create Invite
                                </Button>
                              </Box>
                              
                              {/* <Divider orientation="vertical" variant="middle" flexItem sx={{ margin: "0 10px", backgroundColor: "#fff"}} /> */}
                              {/* <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                                <Badge badgeContent={4} color="error">
                                    <MailIcon />
                                </Badge>
                              </IconButton>
                              <IconButton
                                size="large"
                                aria-label="show 17 new notifications"
                                color="inherit"
                              >
                                <Badge badgeContent={17} color="error">
                                    <NotificationsIcon />
                                </Badge>
                              </IconButton> */}
                              <IconButton
                                size="large"
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                color="inherit"
                              >
                                <AccountCircle />
                              </IconButton>
                          </Box>
                          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                              <IconButton
                                size="large"
                                aria-label="show more"
                                aria-controls={mobileMenuId}
                                aria-haspopup="true"
                                onClick={handleMobileMenuOpen}
                                color="inherit"
                              >
                                <MoreIcon />
                              </IconButton>
                          </Box>
                          {renderMobileMenu}
                          {renderMenu}
                        </>
                      )}
                  </Toolbar>
              </AppBar>
          {/* </ThemeProvider> */}
          {/* : showLogoutContent} */}
        </Box>
    </div>
  );
}
