import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import { NavLink , useLoaderData, useNavigate} from "react-router-dom";
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import HighlightOff from '@mui/icons-material/HighlightOff';
import PlayArrow from '@mui/icons-material/PlayArrow';
import Pause from '@mui/icons-material/Pause';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import LoadingButton from '@mui/lab/LoadingButton';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import { CopyBlock } from 'react-code-blocks';
import ReactGA from "react-ga4";

// Parasail imports
import { DedicatedDeployment, Metrics, Usage, DedicatedLoadGenLink, ApiKey } from "react-app-env";
import BasicBreadcrumbs from "components/BasicBreadcrumbs";
import PageHeader from "components/PageHeader";
import {  FetchDedicatedAPI } from "service/FetchService";
import { useUserContext } from "components/providers/UserContext";
import ModelUsagesPane from "components/views/common/ModelUsagesPane";
import ModelStatusIcon from 'components/views/common/ModelStatusIcon';
import { Logger } from "utils/Logger";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

type DeploymentData = {
  deployment: DedicatedDeployment;
  deployments: DedicatedDeployment[];
}

const GET_DELOYMENTS_TIMER = 30000;// every 30 seconds

const DedicatedHomeView = () => {
  const [dedicatedDeployment, setDedicatedDeployment] = React.useState<DedicatedDeployment>(Object);
  const [dedicatedDeploymentID, setDedicatedDeploymentID] = React.useState<string | undefined>('');
  const [loading, setLoading] = React.useState(false);
  const [dedicatedDeployments, setDedicatedDeployments] = React.useState(Array<DedicatedDeployment>);
  const [metricsUrls, setMetricsUrls] = React.useState(Array<Metrics>);
  const [loadGenLinks, setLoadGenLinks] = React.useState(Array<DedicatedLoadGenLink>);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openFailed, setOpenFailed] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openCreateKey, setOpenCreateKey] = React.useState(false);
  const [metricValue, setMetricValue] = React.useState('1');
  const [usagesData, setUsagesData] = React.useState(Array<Usage>)
  const [dedicatedKey, setDedicatedKey] = React.useState<ApiKey>(Object);

  const deploymentsData = useLoaderData() as DeploymentData;// latest dedicated data from route loader
  // const { deploymentId } = useParams(); // deployment id from url param
  const navigate = useNavigate();

  const { UserContextService } = useUserContext();
  
  // Fetch latest for current deployment and update its status
  const updateCurrentDeployment = React.useCallback((dedicatedDeploymentID: string | undefined) => {
    // Update the current deployment
    if (dedicatedDeploymentID && dedicatedDeploymentID !== '') {
      FetchDedicatedAPI.getDeployment(dedicatedDeploymentID)
        .then((response) => {
          Logger.log("Latest getDeployment", dedicatedDeploymentID, response);
          setLoading(false);

          // update this deployment in dedicatedDeployments list
          setDedicatedDeployment(response as DedicatedDeployment);
          setDedicatedDeployments(dedicatedDeployments?.map(model => {
              if (model.id === response.id) {
                model.status = response.status;
              } 
              return model;
            })
          );
        })
        .catch((e) => {
          Logger.log(e);
        });
    }
  }, [dedicatedDeployments]);

  React.useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/dedicated", title: "dedicated dashboard page" });
  }, []);

  React.useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      Logger.log("Calling latest for current deployment (30 secs) id: ", dedicatedDeploymentID);
      // Update the current deployment
      if(dedicatedDeploymentID) {
        FetchDedicatedAPI.getDeployments().then((response) => {
          Logger.log("Latest getDeployments response", response);
          setLoading(false);
          const newDedicatedDeployments = response as DedicatedDeployment[];
          // Update all deployments
          setDedicatedDeployments(newDedicatedDeployments);
          // Update current deployment
          const selectedDeployment = newDedicatedDeployments?.filter(deployment => deployment?.id?.toString() === dedicatedDeploymentID)[0] as DedicatedDeployment;// assuming one returns
          setDedicatedDeployment(selectedDeployment);
        }).catch((e) => {
          Logger.log(e);
        });
      }
      // updateCurrentDeployment(dedicatedDeploymentID);
    }, GET_DELOYMENTS_TIMER); // every 30 seconds

    //Clearing the interval
    return () => clearInterval(interval);
  }, [
    dedicatedDeploymentID,
    setLoading,
    setDedicatedDeployment,
  ]);

  React.useEffect(() => {
    if(dedicatedDeploymentID) {
      FetchDedicatedAPI.getLoadGenLinks(dedicatedDeploymentID).then((response) => {
        Logger.log('getLoadGen response', response);
        setLoadGenLinks(response);
      }).catch((e) => {
        Logger.log(e);
      });

      FetchDedicatedAPI.getMetrics(dedicatedDeploymentID).then((response) => {
        Logger.log('getMetrics response', response);
        setMetricsUrls(response as Metrics[]);
      }).catch((e) => {
        Logger.log(e);
      });

      FetchDedicatedAPI.getUsages(dedicatedDeploymentID).then((response) => {
        Logger.log('FetchDedicatedAPI.getUsages response', response);
        setUsagesData(response as any);
      }).catch((e) => {
        Logger.log(e);
      });
    }
  }, [dedicatedDeploymentID]);

  
  React.useEffect(() => {
    // Set the existing deployments from route loader for initialization of page
    const isLatestDeployments = dedicatedDeployments && dedicatedDeployments.length !== 0;
    const currentDeployments = isLatestDeployments ? dedicatedDeployments : deploymentsData?.deployments as DedicatedDeployment[];
    const currentDeployment =  deploymentsData?.deployment as DedicatedDeployment;

    let currentDeploymentId = currentDeployment?.id || dedicatedDeploymentID;

    // set pass through deployment model or set default deployment selection if none is pass
    const deploymentItem: DedicatedDeployment = currentDeployment ? currentDeployment : currentDeployments?.filter(item => item.id?.toString() === currentDeploymentId)[0];
    const deployment = deploymentItem ? deploymentItem : currentDeployments[0];// default to first deployment if we do not have a deployment passed thru
    currentDeploymentId = currentDeploymentId || deployment?.id;

    // set latest deployments, deployment, and deployments's id to display
    setDedicatedDeployments(currentDeployments);
    setDedicatedDeployment(deployment);
    setDedicatedDeploymentID(currentDeploymentId?.toString());
  }, [deploymentsData, deploymentsData.deployment, deploymentsData.deployments, dedicatedDeployments, dedicatedDeploymentID]);

  const handleMetricValueChange = (event: React.SyntheticEvent, newValue: string) => {
    setMetricValue(newValue);
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // deployment dropdown change
  const handleDeploymentChange = (e: SelectChangeEvent) => {
    const id = e.target.value as string;
    const deployment = dedicatedDeployments.filter(item => item.id?.toString() === id)[0];
    Logger.log('selected deployment id: ', id, deployment);
    setDedicatedDeployment(deployment);
    setDedicatedDeploymentID(deployment?.id?.toString());
    navigate(`/dedicated/${deployment?.id?.toString()}`);
  }

  const handleDeploymentStart = () => {
    if(dedicatedDeploymentID) {
      setLoading(true);

      // Save and deploy new model
      FetchDedicatedAPI.setDeploymentStart(dedicatedDeploymentID).then((response) => {
        Logger.log('getDeploymentStart response', response);
        setLoading(false);
        if(response === undefined) {
          setOpenFailed(true);
          return; 
        }
        // Call toast notification (snackbar) start successful
        // setOpenSuccess(true);
        // Update the current deployment
        updateCurrentDeployment(dedicatedDeploymentID);
      }).catch((e) => {
        Logger.log(e);
      });

      // Send a custom event
      ReactGA.event({
        category: "dedicated-deployments",
        action: "dedicated-deployments-start-button-click",
        label: "dedicated-deployments-start-button-click", // optional
        // value: 1, // optional, must be a number
        nonInteraction: false, // optional, true/false
        // trans port: "xhr", // optional, beacon/xhr/image
      });
    }
  }

  const handleDeploymentPause = () => {
    if(dedicatedDeploymentID) {
      setLoading(true);

      // Save and deploy new model
      FetchDedicatedAPI.setDeploymentPause(dedicatedDeploymentID).then((response) => {
        Logger.log('getDeploymentPause response', response);
        setLoading(false);
        if(response === undefined) {
          setOpenFailed(true);
          return; 
        }
        // Call toast notification (snackbar) pause successful
        // setOpenSuccess(true);
        // Update the current deployment
        updateCurrentDeployment(dedicatedDeploymentID);
      }).catch((e) => {
        Logger.log(e);
      });

      // Send a custom event
      ReactGA.event({
        category: "dedicated-deployments",
        action: "dedicated-deployments-pause-button-click",
        label: "dedicated-deployments-pause-button-click", // optional
        // value: 1, // optional, must be a number
        nonInteraction: false, // optional, true/false
        // trans port: "xhr", // optional, beacon/xhr/image
      });
    }
  }

  const handleDeploymentDestroy = () => {
    if(dedicatedDeploymentID) {
      setLoading(true);
      handleClose();

      // Save and deploy new model
      FetchDedicatedAPI.setDeploymentDestroy(dedicatedDeploymentID).then((response) => {
        Logger.log('setDeploymentDestroy response', response);
        setLoading(false);
        if(response === undefined) {
          setOpenFailed(true);
          return; 
        }
        // Call toast notification (snackbar) destroyed successful
        // setOpenSuccess(true);
        // update the model from the dedicatedDeployments list
        setDedicatedDeployments(dedicatedDeployments?.filter(model => model.id?.toString() !== dedicatedDeploymentID));
        setDedicatedDeploymentID(dedicatedDeployments[0]?.id?.toString()); // set new id to first one on the list
        navigate(`/dedicated/${dedicatedDeployments[0]?.id?.toString()}`);
      }).catch((e) => {
        setOpenFailed(true);
        Logger.log(e);
      });

      // Send a custom event
      ReactGA.event({
        category: "dedicated-deployments",
        action: "dedicated-deployments-destroy-button-click",
        label: "dedicated-deployments-destroy-button-click", // optional
        // value: 1, // optional, must be a number
        nonInteraction: false, // optional, true/false
        // trans port: "xhr", // optional, beacon/xhr/image
      });
    }
  }

  const handleCloseSuccessSnackBar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSuccess(false);
  };

  const handleCloseFailedSnackBar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenFailed(false);
  };

  const handleCloseCreateKey = () => setOpenCreateKey(false);
  const handleOpenCreateKey = () => {
    Logger.log('handleCreateKey');

    if(dedicatedDeploymentID) {
      setLoading(true);
      setOpenCreateKey(true);

      // Generate key
      FetchDedicatedAPI.setDeploymentKey(dedicatedDeploymentID).then((response) => {
        Logger.log('handleCreateKey response', response, dedicatedDeploymentID);
        setLoading(false);
        if(response) {
          setDedicatedKey(response);
        }
      }).catch((e) => {
        Logger.log('Generate key fetch error: ', e.code);
      });
    }
  }

  return (
    <>
      <BasicBreadcrumbs root="Home" primary="Dedicated Deployments" url="/home" />
      
      {/* Page title & Create new model button */}
      <Stack 
        direction="row"
        // justifyContent="space-between"
        alignItems="center"
        spacing={3}
      >
        <PageHeader title="Dedicated Deployments" width="0"/>

        
        <NavLink to="/dedicated/new?isDashBoard=true"  style={{textTransform :"none"}}>
          <Button variant="contained" size="small" startIcon={<CloudDoneIcon />}>
            Create Dedicated Model
          </Button>
        </NavLink>
      </Stack>

      <div className="bg-white dark:bg-black" style={{ /*minHeight: "820px",*/ display: "flex", alignItems: "stretch", justifyContent: "center"}}>
        <Box className="w-full" sx={{ padding: "15px", marginBottom: "20px" }}>
          <FormControl fullWidth>
            <Grid container spacing={2}>
              {/* model selection */}
              <Grid size={{ xs: 12, md: 12 }}>
                {/* Only show id we have deployments */}
                {dedicatedDeployments && dedicatedDeployments.length > 0 ? (
                  <>
                    <InputLabel id="demo-simple-select-label" >Deployments ({dedicatedDeployments?.length})</InputLabel>
                    <Select 
                      labelId="demo-simple-select-label"
                      value={dedicatedDeploymentID} 
                      onChange={handleDeploymentChange} 
                      id="deployments" 
                      style={{minWidth: "150px",fontSize: "1.2em", marginRight: "20px"}}
                      autoWidth
                      label="Deployments"
                      >
                        <MenuItem value="default" disabled style={{fontSize: "1.2em"}}>Choose a deployment</MenuItem>
                        {dedicatedDeployments?.map(model => {
                          return (
                            <MenuItem key={model.id} value={model.id?.toString()} style={{fontSize: "1.2em"}}><ModelStatusIcon status={model?.status?.status?.toString()} /><span style={{marginLeft: "5px"}}>{model.deploymentName}</span> </MenuItem>
                          )
                        })}
                    </Select>
                  </>
                ) : <>No deployments.</> }

              </Grid>
              {/* model selection ends */}

              {dedicatedDeployments && dedicatedDeployments.length > 0 && (
                <>
                  {/* model actions */}
                  <Grid size={{ xs: 12, md: 12 }}>
                    {/* <Grid size={{ xs: 6, md: 12 }}>
                      <div className="font-bold py-1 flex items-center text-lg text-gray-800 after:flex-1 after:border-t after:border-gray-200 after:ms-6 dark:text-white dark:after:border-neutral-600">
                        <span style={{marginRight: "5px"}}>Details</span>
                      </div>
                      <p className="py-1 flex items-center text-sm text-blue-400">
                        Control selected deployment
                      </p>
                    </Grid> */}

                    { dedicatedDeployment && Object.keys(dedicatedDeployment).length !== 0 ? 
                    (
                    <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={1} sx={{ mb: 1 }} style={{maxWidth: '750px', padding: "15px", border: "1px solid #DDD"}}>
                      <LoadingButton
                        variant="outlined"
                        onClick={handleDeploymentStart}
                        loading={loading}
                        loadingIndicator="Starting..."
                        startIcon={<PlayArrow />}
                        disabled={dedicatedDeployment?.status?.status === 'STARTING' || dedicatedDeployment.status?.status === 'ONLINE' || dedicatedDeployment.status?.status === 'ERROR'}
                        size="small"
                        style={{textTransform :"none"}}
                      >
                        Start
                      </LoadingButton>
                      <LoadingButton
                        variant="outlined"
                        onClick={handleDeploymentPause}
                        loading={loading}
                        loadingIndicator="Pausing..."
                        startIcon={<Pause />}
                        disabled={dedicatedDeployment.status?.status === 'STOPPING' || dedicatedDeployment.status?.status === 'OFFLINE'}
                        size="small"
                        style={{textTransform :"none"}}
                      >
                        Pause
                      </LoadingButton>
                      <LoadingButton
                        variant="outlined"
                        onClick={handleOpen}
                        loading={loading}
                        loadingIndicator="Destroying..."
                        startIcon={<HighlightOff />}
                        size="small"
                        style={{textTransform :"none"}}
                      >
                        Destroy
                      </LoadingButton>

                      <Divider orientation="vertical" variant="middle" flexItem />
                      
                      <NavLink to={`/dedicated/edit?isEditMode=true&isDashBoard=true`} state={dedicatedDeployment || {}} style={{textTransform :"none"}}>
                        <Button variant="contained" size="small">
                          Edit
                        </Button>
                      </NavLink>
                      
                      <LoadingButton
                        variant="contained"
                        onClick={handleOpenCreateKey}
                        loading={loading}
                        loadingIndicator="Opening modal..."
                        size="small"
                        style={{textTransform :"none"}}
                      >
                        Create Key
                      </LoadingButton>
                      
                      <NavLink to={`/dedicated/${dedicatedDeploymentID}/keys`} state={dedicatedDeployment || {}} style={{textTransform :"none"}}>
                        <Button variant="contained" size="small">
                          View Api Keys
                        </Button>
                      </NavLink>
                      
                      {/* <Button variant="contained" size="small">
                        <NavLink to={`/dedicated/new?isCloneMode=true&isDashBoard=true&modelName=${dedicatedDeployment.deploymentName}`} state={dedicatedDeployment || {}}>
                          Clone
                        </NavLink>
                      </Button> */}
                    </Stack>
                    ) : (<p className="px-2 flex items-center text-sm">No deployments.</p>)}
                  </Grid>
                  {/* model actions ends */}

                  {/* model details */}
                  <Grid size={{ xs: 12, md: 12 }}>
                    {dedicatedDeployment && Object.keys(dedicatedDeployment).length !== 0 ? (
                    <Grid container>

                      <Grid size={{ xs: 12, md: 4 }} sx={{maxWidth: "350px", marginRight: "10px"}}>
                        <List dense={true} sx={{padding: '0'}} >
                          <ListItem>
                            <ListItemText
                              primary="Model"
                              secondary={dedicatedDeployment.modelName}
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary="Service Tier"
                              secondary={dedicatedDeployment.serviceTierName + (dedicatedDeployment.replicas <= 1 ? "" : ", " + dedicatedDeployment.replicas + " replicas")}
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary="ID"
                              secondary={dedicatedDeployment.id}
                            />
                          </ListItem>
                        </List>
                      </Grid>
                      <Grid size={{ xs: 12, md: 4 }} sx={{maxWidth: "700px", marginRight: "10px"}}>
                        <List dense={true} sx={{padding: '0'}}>
                          <ListItem>
                            <div className="MuiListItemText-root MuiListItemText-dense MuiListItemText-multiline css-konndc-MuiListItemText-root">
                              <span className="dark:text-white MuiTypography-root MuiTypography-body2 MuiListItemText-primary css-et1ao3-MuiTypography-root">
                                {`Status -  ${dedicatedDeployment.status?.status}`}
                              </span>
                              <div 
                                className="MuiTypography-root MuiTypography-body2 MuiListItemText-secondary css-83ijpv-MuiTypography-root">
                                {dedicatedDeployment && dedicatedDeployment.status?.instances?.map((instance, index) => {
                                    const indexFormatted = index + 1;
                                    return (
                                      <div key={indexFormatted} className="dark:text-gray-400">Replica {indexFormatted} is {instance.status}: {instance.statusMessage}</div>
                                    ); 
                                  })}
                              </div>
                            </div>
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>
                    ) : <div className="block flex self-start mt-2 mb-2"></div>}
                  </Grid>
                  {/* model details ends */}

                  {/* model performance metrics - Gragana */}
                  <Grid size={{ xs: 12, md: 12 }}>
                    {/* <Grid size={{ xs: 12, md: 12 }}>
                      <div className="font-bold flex items-center text-lg text-gray-800 after:flex-1 after:border-t after:border-gray-200 after:ms-6 dark:text-white dark:after:border-neutral-600">
                        Metrics
                      </div>
                      <p className="flex items-center text-sm text-blue-400">
                        Performance metrics
                      </p>
                    </Grid> */}
                  
                    <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
                      {/* Tab per metric */}
                      {metricsUrls && metricsUrls.length !== 0 ? (
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={metricValue}>
                          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleMetricValueChange} aria-label="lab API tabs example">
                              { metricsUrls?.map((metric, index) => {
                                const indexFormatted = index + 1;
                                return (
                                  <Tab key={`tab-${indexFormatted.toString()}`} label={metric.type} value={indexFormatted.toString()} />
                                ); 
                              })}
                            </TabList>
                          </Box>
                          { metricsUrls?.map((metric, index) => {
                            const indexFormatted = index + 1;
                            return (
                              <TabPanel key={`tab-${indexFormatted.toString()}`} value={indexFormatted.toString()} sx={{padding: "5px"}}>
                                <iframe title={metric.type} src={metric.url} width="100%" height="400"></iframe>
                              </TabPanel>
                            ); 
                          })}
                        </TabContext>
                      </Box>
                      ) : (<p className="px-2 flex items-center text-sm">No Metrics</p>)}
                    </Stack>
                  </Grid>
                  {/* model performance metrics ends */}

                  {/* model deployed usages */}
                  <Grid size={{ xs: 12, md: 12 }}>
                    {/* <Grid size={{ xs: 12, md: 12 }}>
                      <div className="font-bold flex items-center text-lg text-gray-800 after:flex-1 after:border-t after:border-gray-200 after:ms-6 dark:text-white dark:after:border-neutral-600">
                        Usages
                      </div>
                    </Grid> */}
                  
                    {dedicatedDeployment && Object.keys(dedicatedDeployment).length !== 0 && dedicatedDeployment.status?.status === 'ONLINE' ? (
                    <Stack direction="row">
                      <Grid container spacing={0} sx={{ width: '100%', minHeight: '600px'}}>
                      <Grid size={{ xs: 12, md: 12 }}>
                        {usagesData && Object.keys(usagesData).length !== 0 ? (
                          <ModelUsagesPane data={[dedicatedDeployment]} usagesData={usagesData} />
                        ) : (<p className="px-2 flex items-center text-sm">No Usages</p>)}
                        </Grid>
                      </Grid>
                    </Stack>
                    ) : (<p className="px-2 flex items-center text-sm">Usage is available for active deployment.</p>)}
                  </Grid>
                  {/* model deployed usages ends */}

                  {/* model test - loadgen/Admin only area */}
                  {UserContextService.userState.isAdmin && (
                    <Grid size={{ xs: 12, md: 12 }}>
                      <Grid size={{ xs: 12, md: 12 }}>
                        <div className="font-bold py-2 flex items-center text-lg text-gray-800 after:flex-1 after:border-t after:border-gray-200 after:ms-6 dark:text-white dark:after:border-neutral-600">
                          Tests (Admin)
                        </div>
                        <p className="py-1 flex items-center text-sm text-blue-400">
                          Functional and stress testing the deployment
                        </p>
                      </Grid>

                      <Stack direction="row" spacing={1} sx={{ mb: 1 }} style={{padding: "10px", border: "1px solid #DDD"}}>
                        <List>
                        {loadGenLinks && loadGenLinks.map((link, index) =>
                          <ListItem key={`loadgen-${index.toString()}`}>
                            <Link target="_blank" href={link.url} underline="always" style={{marginRight: "5px"}}>
                              {link.label}
                            </Link>
                            - {link.description}
                          </ListItem>
                        )}
                        </List>
                      </Stack>
                    </Grid>
                  )}
                </>
              )}

            </Grid>
            {/* model test ends */}
          </FormControl>
        </Box>
      </div>

      <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleCloseSuccessSnackBar}>
        <Alert
          onClose={handleCloseSuccessSnackBar}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Action succeeded!
        </Alert>
      </Snackbar>
      <Snackbar open={openFailed} autoHideDuration={6000} onClose={handleCloseFailedSnackBar}>
        <Alert
          onClose={handleCloseFailedSnackBar}
          severity="warning"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Action failed!
        </Alert>
      </Snackbar>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Confirm destroy of {dedicatedDeployment?.deploymentName}?
          </Typography>
          <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={1} sx={{ mt: 2 }} style={{padding: "15px", border: "1px solid #DDD", backgroundColor: "#f7f6f9"}}>
            <LoadingButton 
              variant="outlined" 
              onClick={handleClose} 
              loading={loading}
              loadingIndicator="Canceling..."
              size="small"
            >
              Cancel
            </LoadingButton>  
            <LoadingButton 
              variant="outlined" 
              onClick={handleDeploymentDestroy} 
              // disabled={showDeployButton}
              loading={loading}
              loadingIndicator="Destroying..."
              size="small"
            >
              Confirm
            </LoadingButton>  
          </Stack>
        </Box>
      </Modal>

      <Modal
        open={openCreateKey}
        onClose={handleCloseCreateKey}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            New Api Key
          </Typography>
          <div className="mt-2 mb-2">Please save this key for future use. You will not see this key again.</div>
          <Grid style={{ paddingLeft: "0", alignContent: "center" }}>
            {dedicatedKey && Object.keys(dedicatedKey).length !== 0 ? (
            <Grid container>
              <Grid sx={{minWidth: '100%', backgroundColor: "#f7f6f9"}}>  
                <CopyBlock
                  text={dedicatedKey?.token || ""}
                  language={"plaintext"}
                  showLineNumbers={false}
                  wrapLongLines={true}
                />
              </Grid>
            </Grid>
            ) : <div className="mt-2 mb-2"></div>}
          </Grid>
          <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={1} sx={{ mt: 2 }} style={{padding: "15px", border: "1px solid #DDD", backgroundColor: "#f7f6f9"}}>
            <LoadingButton 
              variant="outlined" 
              onClick={handleCloseCreateKey} 
              loading={loading}
              loadingIndicator="Canceling..."
              size="small"
            >
              Close
            </LoadingButton>  
            {/* <LoadingButton 
              variant="contained" 
              onClick={handleCreateKey} 
              // disabled={showDeployButton}
              loading={loading}
              loadingIndicator="Generating key..."
              size="small"
              endIcon={<Key />}
            >
              Generate Key
            </LoadingButton> */}
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default DedicatedHomeView;