import * as React from 'react';
import { DataGrid, GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import { useLoaderData } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// import Modal from '@mui/material/Modal';
import ReactGA from "react-ga4";
import moment from 'moment'; 
import Grid from '@mui/material/Grid2';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import LinearProgress, { LinearProgressProps, linearProgressClasses } from '@mui/material/LinearProgress';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Popover from '@mui/material/Popover';
import Modal from '@mui/material/Modal';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import CloudDownload from '@mui/icons-material/CloudDownload';
import Cancel from '@mui/icons-material/Cancel';

// Parasail imports
import BasicBreadcrumbs from "components/BasicBreadcrumbs";
import PageHeader from "components/PageHeader";
import CustomNoRowsOverlay from "components/tables/CustomNoRowsOverlay";
import ModelUsagesPane from "components/views/common/ModelUsagesPane";
import { Logger } from "utils/Logger";
import { 
  FetchBatchAPI 
} from "service/FetchService";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AccordionCustom = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  // '&:not(:last-child)': {
  //   borderBottom: 0,
  // },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummaryCustom = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowDropDownIcon sx={{ fontSize: '1.5rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  // flexDirection: 'row-reverse',
  // '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
  //   transform: 'rotate(90deg)',
  // },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));
const AccordionDetailsCustom = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary' }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const BorderLinearProgressWithLabel = styled(LinearProgressWithLabel)(({ theme }) => ({
  height: 20,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.grey[800],
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    // backgroundColor: '#1a90ff',
    ...theme.applyStyles('dark', {
      backgroundColor: '#308fe8',
    }),
  },
}));
const GET_DELOYMENTS_TIMER = 20000;// every 20 seconds

const BatchHomeView = () => {
  const [currentBatchData, setCurrentBatchData] = React.useState<any>();
  const [currentBatch, setCurrentBatch] = React.useState<any>();
  const [batchCancelOpen, setBatchCancelOpen] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(false);
  // const [progress, setProgress] = React.useState(12);
  // const [hasProgressError, setHasProgressError] = React.useState(false);
  // const [isProgressComplete, setIsProgressComplete] = React.useState(false);

  // for popover tooltip
  const [batchTooltipText, setBatchTooltipText] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>, text: React.SetStateAction<string>) => {
    setBatchTooltipText(text);
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const batchData = useLoaderData() as any;

  // const { id } = useParams();
  // // get the pass thru state
  // let location = useLocation();

  React.useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/batch", title: "Batch page" });
  }, []);

  React.useEffect(() => {
    Logger.log(batchData);

    // save batch data from route loader
    if (batchData) {
      setCurrentBatchData(batchData as any);
    }
  }, [batchData]);

  // refetch batches every 20 secs
  React.useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(async () => {
      Logger.log('Calling latest batch data (20 secs)...');

      setShowLoader(currShowLoader => !currShowLoader);
      const res1 = await FetchBatchAPI.getUsages();
      const batchUsages = res1 as any;

      const res2 = await FetchBatchAPI.getBatches();
      const batches = res2 as any[];

      const latestBatchData = { batchUsages, batches };
      Logger.log("latestBatchData", latestBatchData);

      // Update latest batch data
      setCurrentBatchData(latestBatchData);
      setShowLoader(currShowLoader => !currShowLoader);
    }, GET_DELOYMENTS_TIMER);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [
    setCurrentBatchData,
  ]);

  // batch cancel modal open/close handlers
  const handleBatchCancelOpen = (batch: any) => {
    setCurrentBatch(batch);
    setBatchCancelOpen(true);
  }
  const handleBatchCancelClose = () => setBatchCancelOpen(false);
  
  const handleInputUrlClick = (id: string) => {
    Logger.log('Batch - handleInputUrlClick');
    FetchBatchAPI.getInputUrl(id).then((response) => {
      Logger.log('getInputUrl response', id);

      // redirect to url
      const url = response?.toString();
      if (url) {
        window.open(
          url,
          '_blank' // <- This is what makes it open in a new window.
        );
      }
    }).catch((e) => {
      Logger.log(e);
    });
  };

  const handleOutputUrlClick = (id: string) => {
    Logger.log('Batch - handleOutputUrlClick');
    FetchBatchAPI.getOutputUrl(id).then((response) => {
      Logger.log('getOutputUrl response', id);

      // redirect to url
      const url = response?.toString();
      if (url) {
        window.open(
          url,
          '_blank' // <- This is what makes it open in a new window.
        );
      }
    }).catch((e) => {
      Logger.log(e);
    });
  };

  const handleErrorUrlClick = (id: string) => {
    Logger.log('Batch - handleErrortUrlClick', id);
    FetchBatchAPI.getErrorUrl(id).then((response) => {
      Logger.log('getErrorUrl response', id);

      // redirect to url
      const url = response?.toString();
      if (url) {
        window.open(
          url,
          '_blank' // <- This is what makes it open in a new window.
        );
      }
    }).catch((e) => {
      Logger.log(e);
    });
  };

  const handleCancelBatchClick = () => {
    Logger.log('Batch - handleCancelBatchClick', currentBatch);
    FetchBatchAPI.setCancel(currentBatch.id).then((response) => {
      Logger.log('setCancel response', currentBatch.id, response);
      handleBatchCancelClose();
    }).catch((e) => {
      Logger.log(e);
    });
  };

  const columns: GridColDef[] = [
    { field: 'externalId', width: 200, renderHeader: () => (
      <strong>
        {'External ID'}
      </strong>
    ), renderCell: (params: GridRenderCellParams) => {
      return (
        <Box sx={{ mt: 1, mb: 1 }}>        
          {params.value}
        </Box>
      );
    }},
    { field: 'createdAt', width: 200, renderHeader: () => (
      <strong>
        {'Dates'}
      </strong>
    ), renderCell: (params: GridRenderCellParams) => {
      const createdAt = moment.unix(params.row.createdAt);
      const completedAt = moment.unix(params.row.completedAt);
      const createdAtFormatted = createdAt.fromNow(false);
      const createdAtFullFormatted = createdAt.format("dddd, Do MMM YYYY, h:mm:ss A");
      const completedAtFormatted = completedAt.fromNow(false);
      const completedAtFullFormatted = createdAt.format("dddd, Do MMM YYYY, h:mm:ss A");

       return (
        <Box sx={{ mt: 1, mb: 1 }}>        
          <div
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={(e) => handlePopoverOpen(e, createdAtFullFormatted)}
              onMouseLeave={handlePopoverClose}
          >Created: {createdAtFormatted}</div>
          <br />
          <div
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={(e) => handlePopoverOpen(e, completedAtFullFormatted)}
              onMouseLeave={handlePopoverClose}
          >Completed: {completedAtFormatted}</div>
        </Box>
       );
     } },
    { field: 'requestCountTotal', width: 300, renderHeader: () => (
      <>
        <strong>
          {'Progress'}
        </strong>
        {showLoader &&
          <CircularProgress className="ml-3" style={{width: "25px", height: "25px"}}/>
        }
      </>
    ), renderCell: (params: GridRenderCellParams) => {
      const requestCountTotal = params.row.requestCountTotal ? params.row.requestCountTotal : 0;
      const requestCountSuccess = params.row.requestCountSuccess ? params.row.requestCountSuccess : 0;
      const requestCountFailed = params.row.requestCountFailed ? params.row.requestCountFailed : 0;;
      const progress = ((requestCountSuccess + requestCountFailed) / requestCountTotal) * 100;
      let colorCode = 'primary';
      let hasFailure = false;

      if (requestCountFailed > 0) {
        // setHasProgressError(currHasProgressError => !currHasProgressError);
        colorCode = 'warning';
        hasFailure = true;
      }
      if (progress === requestCountTotal) {
        // setIsProgressComplete(currIsProgressComplete => !currIsProgressComplete);
        colorCode = 'success';
      }
      // setProgress(progress);
      const text = hasFailure ? `${requestCountSuccess} completed, ${requestCountFailed} failed, out of ${requestCountTotal} total` 
      : `${requestCountSuccess} completed out of ${requestCountTotal} total` ;
     
      return (
        <Box sx={{ mt: 1, mb: 1 }}>      
          <BorderLinearProgressWithLabel 
            value={progress} 
            color={colorCode as any}
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={(e) => handlePopoverOpen(e, text)}
            onMouseLeave={handlePopoverClose}
          />
        </Box>
      );
     } },
     { field: 'status', width: 120, renderHeader: () => (
      <strong>
        {'Status'}
      </strong>
    ), renderCell: (params: GridRenderCellParams) => {
      return (
        <Box sx={{ mt: 1, mb: 1 }}>        
          {params.value}
        </Box>
      );
    }},
    {
      field: 'actions',
      // type: 'actions',
      // headerName: 'Actions',
      width: 200,
      // cellClassName: 'actions',
      // getActions: (params) => {
      //   Logger.log('getActions', params)
      //   return [
      //     <GridActionsCellItem
      //       icon={<DeleteIcon />}
      //       label="Cancel"
      //       // onClick={() => handleOpen(params.row)}
      //       color="inherit"
      //       // component={Link}
      //       // to={`/counterparties/${params.id}`}
      //     />,
      //   ];
      // },
      renderHeader: () => (
        <strong>
          {'Actions'}
        </strong>
      ),
      renderCell: (params: GridRenderCellParams) => {
        const hasInputUrl = params.row.metadata;
        const hasOutputUrl = params.row.hasOutputUrl;
        const hasErrorUrl = params.row.hasErrorUrl;
        const isCancellable = params.row.isCancellable;
        const id = params.row.id;
        
        return (
          <Box sx={{ '& button': { mt: 1, mb: 1 } }}>   
            <div>
              { hasInputUrl && <Button variant="outlined" size="small" onClick={() => handleInputUrlClick(id)}  endIcon={<CloudDownload />} style={{textTransform :"none"}}>Download input</Button>}
              { hasOutputUrl && <Button variant="outlined" size="small" onClick={() => handleOutputUrlClick(id)} endIcon={<CloudDownload />} style={{textTransform :"none"}}>Download output</Button>}
              { hasErrorUrl && <Button variant="outlined" size="small"  onClick={() => handleErrorUrlClick(id)} endIcon={<CloudDownload />} style={{textTransform :"none"}}>Download error</Button>}
              { isCancellable && <Button variant="outlined" size="small" onClick={() => handleBatchCancelOpen(params.row)} endIcon={<Cancel />} style={{textTransform :"none"}}>Cancel batch</Button>}
            </div>
          </Box>
        );
    } },
    {
      field: 'metadata',
      width: 300,
      renderHeader: () => (
        <strong>
          {'Info'}
        </strong>
      ),
      renderCell: (params: GridRenderCellParams) => {
        const metadata = params.row.metadata as any;
        const errors = params.row.errors as Array<any>;
        const metadataList = Object.keys(metadata);

        return (
          <Box sx={{ mt: 1, mb: 1 }}>   
            {metadataList && metadataList.length !== 0 &&
              <Stack direction="column">
                <div><strong>Metadata:</strong></div>
                <div>{metadataList.map(key => (
                  <div>
                    <span className="text-gray-500 dark:text-gray-400">{key}:</span> {metadata[key]}
                  </div>))}
                </div>
              </Stack>
            }

            {errors && errors.length !== 0 &&
              <Stack direction="column" className="mt-3">
                <div><strong>Errors:</strong></div>
                <div>{errors.map(error => {
                  // const errorList = Object.keys(error);

                  // return (
                  //   <div>{errorList.map(key => (<div>{key}: {error[key]}</div>))}</div>
                  // )

                  return (
                    <div style={{color: "#ed6c02"}}>
                      {error && error.line ? `${error.line}: ${error.message}` : `${error.message}` }
                    </div>
                  )
                })}</div>
              </Stack>
            }
          </Box>
        );
    } },
  ];


  return (
    <>
      <BasicBreadcrumbs root="Home" primary="Batch" url="/home" />

      <PageHeader title="Batch" />

      <div className="bg-white dark:bg-black" style={{ width: '100%' }}>
        <AccordionCustom id="batch-usage-pane" className="mb-3">
          <AccordionSummaryCustom aria-controls="panel2d-content" id="panel2d-header">
            <Typography>How to use Batch</Typography>
          </AccordionSummaryCustom>
          <AccordionDetailsCustom>
            <Grid size={{ xs: 12, md: 12 }}>        
              <Stack direction="row">
                <Grid container spacing={0} size={{ xs: 12, md: 12 }}>
                  <Grid size={{ xs: 12, md: 12 }}>
                    {currentBatchData && currentBatchData?.batchUsages?.length !== 0 ? (
                      <ModelUsagesPane data={[]} usagesData={batchData?.batchUsages} />
                    ) : (<p className="px-2 flex items-center text-sm">No batches.</p>)}
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          </AccordionDetailsCustom>
        </AccordionCustom>

        {/* Batch list */}
        <Grid size={{ xs: 12, md: 12 }}>
          <div className="font-bold py-2 flex items-center text-lg text-gray-800 after:flex-1 after:border-t after:border-gray-200 after:ms-6 dark:text-white dark:after:border-neutral-600">
            Batches
          </div>
          <p className="py-1 flex items-center text-sm text-blue-400">
            Details of all batches.
          </p>
        </Grid>
        {currentBatchData && currentBatchData?.batches &&
          <DataGrid 
            rows={currentBatchData.batches} 
            columns={columns} 
            // https://v5.mui.com/x/react-data-grid/row-height/
            getRowHeight={() => 'auto'}
            // getRowClassName={() => 'paxton-table--row'}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            pageSizeOptions={[25, 50, 100]}
            slots={{ noRowsOverlay: CustomNoRowsOverlay }}
            sx={{ '--DataGrid-overlayHeight': '300px' }}
            // checkboxSelection
          />
        }
      </div>

      {/* modal for batch cancel confirmation */}
      <Modal
        open={batchCancelOpen}
        onClose={handleBatchCancelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h6">
            Confirm cancel of {currentBatch?.externalId}?
          </Typography>
          <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={1} sx={{ mt: 2 }} style={{padding: "15px", border: "1px solid #DDD", backgroundColor: "#f7f6f9"}}>
            <LoadingButton 
              variant="outlined" 
              onClick={handleBatchCancelClose} 
              loadingIndicator="Canceling..."
              size="small"
            >
              Cancel
            </LoadingButton>  
            <LoadingButton 
              variant="outlined" 
              onClick={handleCancelBatchClick} 
              // disabled={showDeployButton}
              loadingIndicator="Deleting..."
              // loading={loading}
              size="small"
            >
              Confirm
            </LoadingButton>  
          </Stack>
        </Box>
      </Modal>

      {/* batch tool tip for table */}
      <div>
        <Popover
          id="mouse-over-popover"
          sx={{ pointerEvents: 'none' }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1 }}>{batchTooltipText}</Typography>
        </Popover>
      </div>
    </>
  );
}
export default BatchHomeView;