import React, { createContext, useEffect, useState } from "react";
import { Account, User } from "../../web-portal-application";
import { FetchCommonAPI } from "service/FetchService";
import { Logger } from "utils/Logger";

// State of an active user
interface UserState {
  user: User;
  activeAccount: Account;
  accounts: Account[];
  isAuthenticated: boolean;
  isAdmin: boolean;
  hasActiveAccount: boolean;
}

// Props of what User Context provides
interface UserContextProps {
  UserContextService: {
    userState: UserState;
    setUser: (user: User) => void;
    setActiveAccount: (account: Account) => void;
    setAccounts: (accounts: Account[]) => void;
    setIsAuthenticated: (value: boolean) => void;
    setIsAdmin: (value: boolean) => void;
    setHasActiveAccount: (value: boolean) => void;
    clearUserState: () => void;
    Logout: () => void;
  }
}

interface UserContextProviderProps {
  children?: React.ReactNode;
}

const UserContext = createContext<UserContextProps | null>(null);

export const useUserContext = () => {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserContextProvider');
  }
  return context;
};

const createPermissions = (account: Account) => {
  // account is needed to be set first to retrieve permissions
  const isAuthenticated = (account && account?.accountName?.toLocaleLowerCase() !== '');
  const isAdmin = (account && account.accountName?.toLocaleLowerCase() === 'parasail');
  Logger.log('createPermissions', isAdmin, isAuthenticated, account);
  return { isAdmin, isAuthenticated };
};

export const UserContextProvider: React.FC<UserContextProviderProps> = ({
  children,
}) => {
  const [userState, setUserState] = useState<UserState>({
    user: {} as User,
    activeAccount: {} as Account,
    accounts: [],
    isAuthenticated: false,
    isAdmin: false,
    hasActiveAccount: false,
  });

  // WARNING: UserContext should not be overriden unless intended.

  // Update User from context
  const setUser = (user: User) => {
    setUserState((prevState) => ({
      ...prevState,
      user: user,
    }));
  };

  // Update isAuthenticated from context
  const setIsAuthenticated = (value: boolean) => {
    setUserState((prevState) => ({
      ...prevState,
      isAuthenticated: value,
    }));
  };

  // Update isAdmin from context
  const setIsAdmin = (value: boolean) => {
    setUserState((prevState) => ({
      ...prevState,
      isAdmin: value,
    }));
  };

  // Update hasActiveAccount from context
  const setHasActiveAccount = (value: boolean) => {
    setUserState((prevState) => ({
      ...prevState,
      hasActiveAccount: value,
    }));
  };

  // Update active Account from context
  const setActiveAccount = React.useCallback((account: Account) => {
    setUserState((prevState) => ({
      ...prevState,
      activeAccount: account,
    }));
    // update isAdmin & isAuthenticated & hasActiveAccount
    const permissions =  createPermissions(account);
    setIsAdmin(permissions?.isAdmin as boolean);
    setIsAuthenticated(permissions?.isAuthenticated as boolean);
    setHasActiveAccount(true);
  }, []);

  // Update Accounts from context
  const setAccounts = (accounts: Account[]) => {
    setUserState((prevState) => ({
      ...prevState,
      accounts: accounts,
    }));
  };

  const handleDefaultLoaders = React.useCallback(() => {
    // Fetch User and save to context
    FetchCommonAPI.getUser().then((response) => {
      Logger.log('UserContext getUser response: ', response);
      if(response && Object.keys(response).length !== 0) {
        setUser(response);
      }
    }).catch((e) => {
      Logger.log('UserContext fetch User error: ', e.code);
    });

    // Fetch Account and save to context; Set isAuthenticated here.
    FetchCommonAPI.getAccount().then((response) => {
      Logger.log('UserContext getAccount response: ', response);
      if(response && Object.keys(response).length !== 0) {
        setActiveAccount(response);
      }
    }).catch((e) => {
      Logger.log('UserContext fetch account error: ', e.code);
    });

    // Fetch Accounts and save to context
    FetchCommonAPI.getAccounts().then((response) => {
      Logger.log("UserContext getAccounts response: ", response);
      if (response && Object.keys(response).length !== 0) {
        setAccounts(response);
      }
    })
    .catch((e) => {
      Logger.log("UserContext fetch accounts error: ", e.code);
    });
  }, [setActiveAccount]);

  // Fetch the latest user's state and save to userState
  useEffect(() => {
    // get user's state from session
    const storedState = sessionStorage.getItem('userState');
    if (storedState) {
      setUserState(JSON.parse(storedState));
    }

    // get default user context
    handleDefaultLoaders();

    Logger.log('UserContext is called.............')
  }, [handleDefaultLoaders]);

  // save to session storage when user's state updates
  useEffect(() => {
    sessionStorage.setItem('userState', JSON.stringify(userState));
  }, [userState]);

  // Clear the User state from sessiom storage
  const clearUserState = () => {
    // clean up userState
    sessionStorage.removeItem('userState');
    // sessionStorage.clear();// DO NOT USE FOR NOW. Will wipe for all projects.
  }

  // Log out user
  const Logout = () => {
    Logger.log('UserContext logout');
    // Clear session for user state (this context)
    clearUserState();

    FetchCommonAPI.logout().then((response => {
      // redirect user after logout
      if(response.request.responseURL) {
        window.location.replace(`${response.request.responseURL}`);
      } else {
        window.location.replace(window.location.protocol + "//" + window.location.hostname + (window.location.port ? ":" + window.location.port : "") + "/");
      }
    }));
  }

  // Only allow access to UserContext from what is exposed through this service
  const UserContextService = { 
    userState, 
    setUser,
    setActiveAccount, 
    setAccounts, 
    setIsAuthenticated,
    setIsAdmin,
    setHasActiveAccount,
    clearUserState, 
    Logout 
  }

  return (
    <UserContext.Provider
      value={{ UserContextService }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
